import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

//import core components
import { Layout, Container } from 'layouts';
import Search from 'components/pages/search';
//import { BestSellers } from 'components/pages/home';
import { LinkButton, ImageBanner } from 'components';
import { queryHelper } from 'helpers';

import styled from '@emotion/styled';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const StyledBackgroundImage = styled.div`
  display: flex;

  margin-bottom: 45px;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.1);
  overflow: hidden;

  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 0 20px 20px;
  }
`;

const MAX_PER_PAGE = 50;
const CategoryTemplate = props => {
  const {
    data: { topBanner },
    pageContext: { name, fieldFacets },
  } = props;
  return (
    <Layout title={`Category ${name}`}>
      <Container>          
        {/*<StyledBackgroundImage>
          <GatsbyImage image={getImage(queryHelper.getGatsbyImageData(topBanner))} alt=""/>
        </StyledBackgroundImage>*/}       
        <LinkButton text="Back to Catalog" link="/greeting-cards" />
        <Search category={name} fieldFacets={fieldFacets} />
        {/*<BestSellers />*/}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MyCategoryTemplateQuery {
    topBanner: nodeBanner(title: { eq: "categoryTopBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;
export default CategoryTemplate;
